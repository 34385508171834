// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}


.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.centeredGraph { 
  display: flex;
  justify-content: center;
  align-items: center;
}

.createdDateFavicon {
  font-size: 10px;
  margin-right: 5px;
}

.createdDateBy{
  font-size: 10px;
  margin-right: 5px;
  margin-left: 15px;
}

 .return-value-type {
   background-color:  #FDEDEC
 }

 .table-hov1 {
  background-color: white;
 }

 .table-hov1:hover {
   background-color: #F8F9F9;
 }

 .row-success{
  background-color: #EAFAF1;
 }

 .row-success:hover{
  background-color: #F8F9F9;
 }

 .row-error {
  background-color: #F9EBEA;
 }

 .row-error:hover{
  background-color: #F8F9F9;
 }

 .row-warning {
  background-color: #FEF5E7;
 }

 .row-warning:hover{
  background-color: #FEF5E7;
 }
 
.hiddenRow {
  padding: 0 !important;
}

.react-virtualized-menu-placeholder {
  margin-top: 12px;
  color: #9a9a9a;
  text-align: center;
}
.react-virtualized-list-wrapper li {
  list-style: none;
}

.custom-option {
  transition: background 60ms;
}
.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}
.custom-option.custom-select__option--is-focused {
  background: none;
}
.custom-option.custom-select__option--is-selected {
  background: #2684FF;
}

.url-size{
  font-size: 9px;
}
 